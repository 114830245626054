<template>
<div class="Foot">
  <div style="width: 100%;height: 100px;background-color: #08baa9; display: flex;justify-content: center;align-items: Center;">
    © 2018-2023 备案号：<a href="https://beian.miit.gov.cn/" target="_blank">豫ICP备18002330号-1</a>
  </div>
</div>
</template>

<script>
export default {
  name: "Foot"
}
</script>

<style scoped>

</style>