<template>
  <div id="app" v-title data-title="个人技术思想">
    <router-view/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Hard from "@/components/Hard";
import Foot from "@/components/foot/Foot";


export default {
  name: 'app',
  components: {
  'v-Hard':Hard,
    'v-Foot':Foot
  },
  data(){
    return {
      msg:'hello world',
      books:[]
    }

  },
  created() {
    const _this = this
    axios.get('http://47.103.147.94:8082/').then(function (resp) {
      _this.books = resp.data
    })
  }
}
</script>

<style>
.BTi {
  font-family:"Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  font-size: 20px;
  font-weight: bold;
}
</style>
