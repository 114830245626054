
export default[
    {
        path: '/mengement',
        name: 'Mengement',
        component: () => import('../views/Mengement.vue'),
        meta: {
            menuShow: true,
            menuName:'博客管理',
            requiresAuth: true
        },
        redirect:"/articleStatistical",
        children:[
            {
                path: '/theArticleAdd',
                name: 'TheArticleAdd',
                meta: {
                    menuShow: true,
                    menuName:'文章添加'
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/TheArticleAdd.vue')
            },
            {
                path: '/articleStatistical',
                name: 'ArticleStatistical',
                meta: {
                    menuShow: true,
                    menuName:'文章管理',
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/ArticleStatistical.vue')
            },
            {
                path: '/theArticleEditor',
                name: 'TheArticleEditor',
                meta: {
                    menuShow: false,
                    menuName:'文章编辑'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '../components/theArticleEditor/theArticleEditor.vue')
            },
            {
                path: '/categoryStatistical',
                name: 'categoryStatistical',
                meta: {
                    menuShow: true,
                    menuName:'分类统计'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '../components/categoryStatistical/categoryStatistical.vue')
            }

        ]
    },
    // {
    //     path: '/mengement',
    //     name: 'Mengement',
    //     component: () => import('../views/Mengement.vue'),
    //     meta: {
    //         menuShow: true,
    //         menuName:'用户管理'
    //     },
    //     redirect:"/articleText",
    //     children:[
    //
    //
    //         {
    //             path: '/theArticleEditor',
    //             name: 'TheArticleEditor',
    //             meta: {
    //                 menuShow: false,
    //                 menuName:'文章编辑'
    //             },
    //             // route level code-splitting
    //             // this generates a separate chunk (about.[hash].js) for this route
    //             // which is lazy-loaded when the route is visited.
    //             component: () => import(/* webpackChunkName: "about" */ '../components/theArticleEditor/theArticleEditor.vue')
    //         }
    //
    //     ]
    // },
]
