import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router/index'
import store from './store'
import './plugins/element.js'
import QuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.snow.css'
import qs from 'qs'
Vue.prototype.$qs = qs;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)

}).$mount('#app')

Vue.directive('title', {
    inserted: function (el, binding) {
        document.title = el.dataset.title
    }
})

axios.interceptors.request.use(
    config => {
      // alert(localStorage.getItem("token"))
      if (localStorage.token) {

        config.headers.Authorization = localStorage.token
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    });
