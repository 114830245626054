<template>
<div class="Hard">
  <el-menu
      class="el-menu-demo"
      mode="horizontal"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b">
    <el-menu-item  style="padding-left: 10%" ></el-menu-item>
    <el-menu-item  style="" ><a href="/">首页</a></el-menu-item>
<!--    <el-menu-item ><a href="/">角色宣传</a></el-menu-item>-->
<!--    &lt;!&ndash;    <el-menu-item ><a href="/martialArts">武学查询</a></el-menu-item>&ndash;&gt;-->
<!--    &lt;!&ndash;    <el-menu-item ><a href="/volunteers">风物志查询</a></el-menu-item>&ndash;&gt;-->
<!--    <el-menu-item ><a href="/renameRole">角色改名数据查询</a></el-menu-item>-->
<!--    <el-menu-item ><a href="/sellAdd">卖号宣传</a></el-menu-item>-->
<!--    <el-menu-item ><a href="/buyAdd">收号宣传</a></el-menu-item>-->
<!--    <el-menu-item ><a href="http://treasure.rundstedt.cn" target="_blank">集市宝物查询</a></el-menu-item>-->
<!--    <el-menu-item ><a href="http://jingmai.rundstedt.cn" target="_blank">经脉模拟器</a></el-menu-item>-->
<!--    <el-submenu index="2">-->
<!--      <template  slot="title">九阴外链</template>-->
<!--      <el-menu-item index="2-1" ><a href="http://jishi.woniu.com/resources/9yin/toServerList.html" target="_blank">蜗牛集市</a></el-menu-item>-->
<!--      <el-menu-item index="2-2"><a href="http://9yin.azurewebsites.net" target="_blank">永乐大典</a></el-menu-item>-->
<!--      <el-menu-item index="2-3"><a href="https://9yin.woniu.com/wiki/" target="_blank">九阴维基</a></el-menu-item>-->
<!--    </el-submenu>-->

<!--    <el-menu-item ><a href="/sponsorship">友情赞助</a></el-menu-item>-->
<!--    <el-menu-item ><a href="/log">更新日志</a></el-menu-item>-->
  </el-menu>

</div>
</template>

<script>

export default {
  name: "Hard",
  data() {
    return {
    };
  },
  methods: {
  }
  
}
</script>

<style scoped>
a{
  text-decoration: none
}
</style>
