
export default[
    {
        path: '/category',
        name: 'category',
        meta: {
            menuShow: false,
            menuName:'标签'
        },
        component: () => import(/* webpackChunkName: "about" */ '../components/category/category.vue')
    },
    {
        path: '/articleSelect',
        name: 'Article',
        meta: {
            menuShow: false,
            menuName:'文章查询'
        },
        component: () => import(/* webpackChunkName: "about" */ '../components/article/Article.vue')
    },
    {
        path: '/foot',
        name: 'Foot',
        meta: {
            menuShow: false,
            menuName:'底部'
        },
        component: () => import(/* webpackChunkName: "about" */ '../components/foot/Foot.vue')
    },
    {
        path: '/music',
        name: 'music',
        meta: {
            menuShow: false,
            menuName:'音乐'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/music.vue')
    },
    ]