import Vue from 'vue'
import VueRouter from 'vue-router'
import Management from './Management'
import rount2 from "@/router/rount2";
import TheFunctionPoint from "@/router/TheFunctionPoint";

Vue.use(VueRouter)

const baseRoutes = [];
const routes = baseRoutes.concat(Management,rount2,TheFunctionPoint);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {

    //这里判断用户是否登录，验证本地存储是否有token
    if (!localStorage.token) { // 判断当前的token是否存在
      // alert(localStorage.token)
      next({
        path: '/theLogin',
        query: { redirect: to.fullPath }
      })
    } else if(localStorage.token == "undefined") {
      next({
        path: '/theLogin',
        query: { redirect: to.fullPath }
      })
    }else {
      next()
    }
  } else {
    next() // 确保一定要调用 next()
  }
})



export default router
