
export default[
    {
        path: '/about',
        name: 'About',
        meta: {
            menuShow: false,
            menuName:'导航'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/image',
        name: 'Image',
        meta: {
            menuShow: false,
            menuName:'导航'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Image.vue')
    }
    ,
    {
        path: '/',
        name: 'HomePage',
        meta: {
            menuShow: false,
            menuName:'导航',
            title:'个人技术思想'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/HomePage.vue')
    },
    {
        path: '/article',
        name: 'Article',
        meta: {
            menuShow: false,
            menuName:'导航'
        },
        component: () => import('../views/Article.vue')
    },
    {
        path: '/hard',
        name: 'Hard',
        meta: {
            menuShow: false,
            menuName:'头部'
        },
        component: () => import('../components/Hard.vue')
    },
    {
        path: '/theLogin',
        name: 'TheLogin',
        meta: {
            menuShow: false,
            menuName:'登录'
        },
        component: () => import('../views/TheLogin.vue')
    },
    {
        path: '/showPage',
        name: 'showPage',
        meta: {
            menuShow: false,
            menuName:'标签查询'
        },
        component: () => import('../views/showPage.vue')
    },
]

